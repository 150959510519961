import React from 'react'
import { Form, Button, Upload } from 'antd'
import WSIcon from './WSIcon'
import { UploadOutlined } from '@ant-design/icons'

const UploadImage = (props: any) => {
  const { selectSiteIcon, buttonText, defaultFileList, beforeUpload } = props

  return (
    <Form.Item extra="">
      <Upload
        {...props}
        defaultFileList={defaultFileList}
        name="logo"
        listType="picture"
        onChange={e => e.fileList.length && selectSiteIcon(e.file)}
        multiple={false}
      >
        <Button>
          <WSIcon type={<UploadOutlined />} /> &nbsp;&nbsp; {buttonText}
        </Button>
      </Upload>
    </Form.Item>
  )
}

export default UploadImage
