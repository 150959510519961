import React, { Component, Fragment, GetDerivedStateFromProps } from 'react'
import ReactQuill from 'react-quill' // ES6
import 'react-quill/dist/quill.snow.css' // ES6
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import ImageUploader from 'react-images-upload'

import { Card, Row, Col, Typography, Input, Button, Select } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { createHomeVideo, clearManageVideoFlag, updateHomeVideo } from '../../../store/staticContent/manageVideos/Action'
import { handleSingleFileUpload, handleSingleFileDelete } from '../../../helpers/S3FileUpload'
import History from '../../../History'
import { videoCategory, MEDIA_NAME, MEDIA_TYPE, imageExtensions } from '../../../enums/enums'
import UploadImage from '../../../component/UploadImage'
import WSField from '../../../component/Field'
import WSIcon from '../../../component/WSIcon'
import WSSelect from '../../../component/WSSelect'
import { PlusCircleOutlined } from '@ant-design/icons'
import {
  getAddMedia,
  removeTypeName,
  splitUrlFromUnderscore,
  isEmptyObj,
  removePTag,
  trimmedValue,
} from '../../../helpers/helper'
import NotificationWithIcon from '../../../component/notification/Notification'

const { Text } = Typography
const { Option } = Select

interface Props {
  createHomeVideo: typeof createHomeVideo
  success: boolean
  location: any
  clearManageVideoFlag: typeof clearManageVideoFlag
  updateHomeVideo: typeof updateHomeVideo
  history: typeof History
}
interface State {
  loading: boolean
  invalidVideo?: boolean
  editVideo: any
  addMedia: any[]
  manageVideo: {
    title: string
    videoUrl: any[]
    description: string
    thumbnail: any[]
    type: string
    addMedia: any[]
  }
  category: typeof videoCategory
  isUpdated: Boolean
}

export interface homeVideoStateData {
  videos: []
  success: boolean
  error: boolean
}
export interface homeVideoReducer {
  homeVideoReducer: homeVideoStateData
}

class CreateManageHomeVideo extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      invalidVideo: false,
      editVideo: props.location.state ? props.location.state.video : null,
      manageVideo: {
        title: '',
        videoUrl: [],
        description: '',
        thumbnail: [],
        type: '',
        addMedia: [],
      },
      addMedia: [],
      category: videoCategory,
      isUpdated: true,
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { success, history, clearManageVideoFlag } = props

    if (success) {
      history.push('/manage-videos')
      clearManageVideoFlag()
    }
    return null
  }

  selectSiteIcon = async (file: any, setFieldValue: any, values: any, acceptType: string) => {
    // console.log(file,'FILE');
    // console.log(values,'VALUES');
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')

    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUpload(
        file,
        'thumbnail',
        setFieldValue,
        values.addMedia,
        MEDIA_NAME.MEDIATHUMBNAIL,
        MEDIA_TYPE.IMAGE
      )
      this.setState({ loading: false })
    } else {
      NotificationWithIcon('error', 'File Is Invalid.')
    }
  }

  handleVideoUpload = async (file: any, setFieldValue: any, values: any, acceptType: string) => {
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')
    // let img_file= URL.createObjectURL(file);
    //  console.log(file);alert(img_file);
    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUpload(file, 'videoUrl', setFieldValue, values.addMedia, MEDIA_NAME.MEDIAURL, MEDIA_TYPE.VIDEO)
      this.setState({ loading: false })
    } else {
      NotificationWithIcon('error', 'File Is Invalid.')
    }
  }

  handleRemove = (file: any, setFieldValue: any, values: any) => {
    const { addMedia } = values

    handleSingleFileDelete(file, 'thumbnail', setFieldValue, addMedia, values.thumbnail, 'filter')
  }
  handleVideoRemove = (file: any, setFieldValue: any, values: any) => {
    const { addMedia } = values
    handleSingleFileDelete(file, 'videoUrl', setFieldValue, addMedia, values.videoUrl, 'filter', values)
  }

  prepareValue() {
    const { editVideo, isUpdated, category } = this.state
    if (isUpdated) {
      const selectedCategory: any = category.find((item: any) => item.value.toLowerCase() === editVideo.type.toLowerCase())
      editVideo.type = selectedCategory && selectedCategory.text && selectedCategory.text
      editVideo.addMedia = []
      editVideo.videoUrl = editVideo.url
      const { createdAt, updatedAt, url, ...unnecessary } = editVideo
      this.setState({ isUpdated: false })
      return unnecessary
    }
  }

  render() {
    const { manageVideo, editVideo, category, invalidVideo, loading } = this.state
    const { createHomeVideo, updateHomeVideo, history } = this.props
    const initValue = editVideo ? this.prepareValue() : manageVideo

    return (
      <Fragment>
        <Formik
          initialValues={initValue}
          onSubmit={values => {
            if (editVideo) {
              values.metatype = 'video'
              values.type = values.type || 'boatMedia'
              const { thumbnail, __typename, videoUrl, description, ...noThumbnail } = values

              updateHomeVideo({
                ...noThumbnail,
                addMedia: getAddMedia(noThumbnail.addMedia),
                description: removePTag(description),
              })
            } else {
              values.metatype = 'video'
              values.type = values.type || 'boatMedia'

              const { thumbnail, videoUrl, addMedia, description, ...noThumbnail } = values
              createHomeVideo({
                ...noThumbnail,
                addMedia: getAddMedia(values.addMedia),
                description: removePTag(description),
              })
            }
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required('Video title field is required.'),
            videoUrl: Yup.string().required('Video url field is required.'),
            description: Yup.string().required('Video description field is required.'),
          })}
        >
          {props => {
            const { handleSubmit, setFieldValue, values, errors } = props
            return (
              <form onSubmit={handleSubmit}>
                <Card
                  title={
                    <span>
                      <WSIcon type={<PlusCircleOutlined />} />
                      Home video
                    </span>
                  }
                  extra={
                    <>
                      <Button onClick={() => history.goBack()}>Back</Button>
                      {!loading ? (
                        <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                          Save
                        </button>
                      ) : (
                        <Button loading={loading} className="ml-15 ant-btn ant-btn-primary">
                          Save
                        </Button>
                      )}
                    </>
                  }
                >
                  <Row gutter={16}>
                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="Video Name">
                        Video Name
                      </span>
                      <Field
                        className="ant-input"
                        name="title"
                        placeholder="Video Name"
                        onBlur={(e: { target: { value: string } }) => setFieldValue('title', trimmedValue(e.target.value))}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name={`title`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="Upload Video">
                        Category
                      </span>
                      <WSSelect
                        defaultValue={values.type}
                        onChangeText={(value: any) => setFieldValue('type', value)}
                        arrayList={category && category}
                        listOptions="value"
                        passed="text"
                      />

                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="type" component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" sm={12} md={6} lg={6}>
                      <span className="ant-form-item-required" title="Upload Video">
                        Video Thumbnail
                      </span>
                      <br />

                      {values.thumbnail && values.thumbnail.length ? (
                        <UploadImage
                          defaultFileList={values.thumbnail.map((item: any) => {
                            return {
                              uid: item.id,
                              name: item.name || 'Thumbnail',
                              status: 'done',
                              url: item.url,
                            }
                          })}
                          accept={imageExtensions}
                          buttonText="Uploaded Thumbnail"
                          withIcon
                          openFileDialogOnClick={values.thumbnail && !values.thumbnail.length}
                          selectSiteIcon={(file: any) => this.selectSiteIcon(file, setFieldValue, values, 'image')}
                          onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values)}
                        />
                      ) : (
                        <UploadImage
                          accept={imageExtensions}
                          withIcon
                          buttonText="Video Thumbnail"
                          openFileDialogOnClick={values.thumbnail && !values.thumbnail.length}
                          selectSiteIcon={(file: any) => this.selectSiteIcon(file, setFieldValue, values, 'image')}
                          onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values)}
                        />
                      )}
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="thumbnail" component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" sm={12} md={6} lg={6}>
                      <span className="ant-form-item-required" title="Upload Video">
                        Upload Video
                      </span>

                      {values.videoUrl && values.videoUrl.length ? (
                        <UploadImage
                          defaultFileList={values.videoUrl.map((item: any) => {
                            return {
                              uid: item.id,
                              name: item.name || 'Thumbnail',
                              status: 'done',
                              url: item.url,
                            }
                          })}
                          accept="video/*"
                          buttonText="Uploaded Video"
                          withIcon
                          openFileDialogOnClick={values.videoUrl && !values.videoUrl.length}
                          onRemove={(rem: any) => this.handleVideoRemove(rem, setFieldValue, values)}
                          selectSiteIcon={(file: any) => this.handleVideoUpload(file, setFieldValue, values, 'video')}
                        />
                      ) : (
                        <UploadImage
                          accept="video/*"
                          withIcon
                          buttonText="Upload Video"
                          openFileDialogOnClick={values.videoUrl && !values.videoUrl.length}
                          onRemove={(rem: any) => this.handleVideoRemove(rem, setFieldValue, values)}
                          selectSiteIcon={(file: any) => this.handleVideoUpload(file, setFieldValue, values, 'video')}
                        />
                      )}
                      <div className="video-input">{invalidVideo && <Text type="danger">Invalid file.</Text>}</div>
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="videoUrl" component="span" />
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-box" span={24}>
                      <span className="ant-form-item-required" title="Video Description">
                        Video Description
                      </span>
                      {/* <ReactQuill
												className='editor-height'
												value={values.description}
												onChange={values => {
													setFieldValue('description', values);
												}}
												theme={'snow'}
											/> */}
                      <WSField
                        type="textArea"
                        defaultValue={values.description}
                        onChangeText={(evt: any) => {
                          setFieldValue('description', evt.editor.getData())
                        }}
                        onBlur={(evt: { editor: { getData: () => string } }) =>
                          setFieldValue('description', trimmedValue(removePTag(evt.editor.getData())))
                        }
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`description`}
                          component="span"
                        />
                      </Text>
                    </Col>
                  </Row>
                </Card>
              </form>
            )
          }}
        </Formik>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: homeVideoReducer) => ({
  success: state.homeVideoReducer && state.homeVideoReducer.success,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createHomeVideo: (data: any) => dispatch(createHomeVideo(data)),
  clearManageVideoFlag: () => dispatch(clearManageVideoFlag()),
  updateHomeVideo: (data: any) => dispatch(updateHomeVideo(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateManageHomeVideo)
