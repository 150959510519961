import S3 from 'aws-s3'
import { getLocalStorageItem, nameFormatter, splitUrlFromUnderscore } from './helper'
import { v1 as uuidv1 } from 'uuid'
import axios from 'axios'
import { MEDIA_TYPE } from '../enums/enums'
import { fileType, iconType, removeFileType } from '../store/login/Types'

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: process.env.REACT_APP_DIR_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
}

const S3Client = new S3(config)
const S3ClientVideo = new S3({
  ...config,
  dirName: process.env.REACT_APP_DIR_NAME_VIDEO,
})

export default function uploadToS3(selectedFile: any) {
  return S3Client.uploadFile(selectedFile, (selectedFile && selectedFile.name) || '')
}

export function uploadToS3Video(selectedFile: any) {
  return S3ClientVideo.uploadFile(selectedFile)
    .then((data: any) => data)
    .catch((err: any) => {
      return err
    })
}

export const uploadFile = async (file: any, mediaType: any, siteFileKey: any, multiple = false) => {
  const allFiles = multiple ? file : [file]

  let images: any[] = []
  console.log('uploadFile')

  await Promise.all(
    allFiles &&
      allFiles.map((singleFile: any) => {
        const mimeType = singleFile.type
        const fileKey = siteFileKey || nameFormatter([uuidv1(), mimeType.split('/', 2)[1]], '.')

        let url = `${process.env.REACT_APP_IMAGE_UPLOAD_URL}?filename=${fileKey}&token=${getLocalStorageItem(
          'auth_token'
        )}&fileType=${mimeType}&mediaType=${mediaType.toLowerCase()}`

        return axios
          .get(url)
          .then(async (response: any) => {
            const signedRequest = response.data.signedRequest
            // const url = response.data.url; // Uploaded File URL
            const options = {
              headers: {
                'Content-Type': mimeType,
              },
            }
            await axios
              .put(signedRequest, singleFile.originFileObj || singleFile, options)
              .then(async result => {
                images = [
                  ...images,
                  {
                    url: URL.createObjectURL(singleFile.originFileObj || singleFile),
                    key: fileKey,
                    type: mimeType,
                    name: singleFile.name,
                  },
                ]
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(error => {
            console.log(error, 'erroe')
          })
      })
  )

  return images
}

export const handleSingleFileUpload = async (
  file: any,
  name: any,
  setFieldValue: any,
  addMedia: any,
  mediaName: any,
  mediaType = MEDIA_TYPE.IMAGE
) => {
  if (file) {
    const res = await uploadFile(file, mediaType, null)

    res.length &&
      setFieldValue(name, {
        key: res[0].key,
        name: res[0].name,
        mediaName,
        mediaType,
        type: res[0].type,
      })

    return (
      res.length &&
      setFieldValue('addMedia', [
        ...addMedia,
        ...res.map(item => ({
          ...item,
          mediaName,
          mediaType,
        })),
      ])
    )
  }
}

export const handleSingleFileDelete = async (
  value: any,
  name: any,
  setFieldValue: any,
  addMedia: any,
  valuesData: any,
  method: any,
  formVals?: any
) => {
  const filterData = valuesData && valuesData.length ? valuesData.filter((item: any) => item.id !== value.uid) : []
  console.log('valuesData')
  valuesData &&
    valuesData.length &&
    value &&
    value.uid &&
    setFieldValue('removeMedia', [...(formVals?.removeMedia || []), value.uid])
  setFieldValue(
    'addMedia',
    addMedia.filter((item: any) => item.key !== splitUrlFromUnderscore('_', value.url))
  )
  setFieldValue(name, filterData)
}

export const handleSingleFileUploadObj = async (
  file: fileType,
  name: string,
  setFieldValue: any,
  mediaName: string,
  mediaType = MEDIA_TYPE.IMAGE
) => {
  if (file) {
    const res = await uploadFile(file, mediaType, null)
    return (
      res.length &&
      setFieldValue(name, {
        key: res[0].key,
        name: res[0].name,
        mediaName,
        mediaType,
        type: res[0].type,
      })
    )
  }
}

export const handleSingleFileDeleteObj = async (
  value: removeFileType,
  name: string,
  setFieldValue: any,
  valuesData: iconType
) => {
  const filterData = valuesData && valuesData.length ? valuesData.filter((item: any) => item.id !== value.uid) : []
  setFieldValue(name, filterData)
}
